<script>
  export let dividerAbove = false;
  export let dividerBelow = false;
  export let componentData;

  let contactFormData;
  if(componentData.locale === "en"){
    contactFormData = {
      title: "Contact us",
      submitMessage: 'Thank you for contacting us, we will respond as soon as we can.',
      nameText: 'Name',
      nameError: 'Enter valid name.',
      emailText: 'Email',
      businessText: 'Company',
      businessError: "This field cannot be left empty.",
      emailError: "This is not a valid email address.",
      characterLimitError: "Maximum character limit exceeded.",
      phoneText: 'Phone number',
      phoneErorr: 'Invalid phone number.',
      selectOption: [
        {
          option: 'What does the question pertain to?',
          value: ''
        },
        {
          option: 'Office',
          value: 'Office'
        },
        {
          option: 'Conference',
          value: 'Conference'
        },
        {
          option: 'Private event',
          value: 'Private_event'
        },
        {
          option: 'Others',
          value: 'Others'
        },
      ],
      textArea: 'Message',
      checkBox: 'I accept that my details are saved and managed by 7a',
      emptyFieldsError: 'Fill in all required fields',
      btnText: componentData.ButtonTextAsContactForm
    }
  }
  else
  {
    contactFormData = {
      title: componentData.TitleAsContactForm,
      submitMessage: 'Tack för att du hör av dig till oss, vi kommer att svara dig så fort vi kan.',
      nameText: 'Namn',
      nameError: 'Enter valid name.',
      emailText: 'E-post',
      businessText: 'Företag',
      businessError: "Detta fält får inte vara tomt.",
      emailError: "Detta är inte en giltig mejladress.",
      characterLimitError: "Max antal tecken 450.",
      phoneText: 'Telefonnummer',
      phoneErorr: 'Invalid phone number.',
      selectOption: [
        {
          option: 'Vad gäller frågan?',
          value: ''
        },
        {
          option: 'Kontor',
          value: 'Kontor'
        },
        {
          option: 'Konferens',
          value: 'Konferens'
        },
        {
          option: 'Privat tillställning',
          value: 'Privat_tillställning'
        },
        {
          option: 'Övrigt',
          value: 'Övrigt'
        },
      ],
      textArea: 'Meddelande',
      checkBox: 'Jag accepterar att mina uppgifter sparas och hanteras av 7a',
      emptyFieldsError: 'Fyll i alla obligatoriska fält',
      btnText: componentData.ButtonTextAsContactForm


    }
  }

  let isRequired = true;
  let name = '';
  let email = '';
  let business = '';
  let phone = '';
  let selectValue = '';
  let textAreaValue = '';
  let checked = false;
  let isValidName = true;
  let isValidEmail = true;
  let isValidBusiness = true;
  let isValidPhone = true;
  let isWithinCharacterLimit = true;
  let isValidated = null; // If form is successfully submitted, this parameter must be true
  let emptyFields = null;


  function validationForm() {
      emptyFields = !!(business.trim().length && name.trim().length && email.trim().length && phone.trim().length && checked);      validateName();
      validateEmail();
      validatePhone();
      validateCharacterLimit();

      if (emptyFields && isValidName && isValidEmail && isValidPhone && checked && isWithinCharacterLimit) {
          return true;
      }
      return false;
  }

  function validateName() {
    const NameRegex = /^[a-zA-Z]+[a-zA-Z\s]*?[^0-9]$/;
    return isValidName = name?.length > 1 && NameRegex.test(name);
  }

  function validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return isValidEmail = emailRegex.test(email);
  }

  function validatePhone() {
    const phoneRegex = /^\d+$/;
    return isValidPhone = phone.length > 6 && phoneRegex.test(phone);
  }

  function validateCharacterLimit() {
    console.log('Validating character limit')
    console.log(textAreaValue.length);

    return isWithinCharacterLimit = textAreaValue.length <= 450;
  }

  function handleSubmit(event) {
    if (!validationForm()) {
      console.log('Form is not validated');
      event.preventDefault();
    } else {
      event.preventDefault()
      const __formData__ = {
        name,
        email,
        phone,
        selectValue,
        textAreaValue
      };


      const formData = {
        'Contact.firstname': name,
        'Contact.lastname': 'Web', // You need to define this
        'Contact.email': email,
        'Client.name': business, // You need to define this
        'Contact.cellPhone': phone,
        'Extra.1710923267467': selectValue, // You need to define this
        'Extra.1710923311595': textAreaValue,
        'singleOptIn.y857xq45dvm': 'on', // You need to define this
        'formCid': 8721, // This seems like a static value
        'formId': '8721uc0341b2078e14fc29bd185c430593d40', // This seems like a static value
        'isAjax': true, // This seems like a static value

      };

     fetch('https://power.upsales.com/api/external/formSubmit', {
      //fetch('/sampletestenpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData)
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network error');
        }
      })
      .then((data) => {
        isValidated = true;
        console.log('Form data submitted successfully:', data);

      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
    }
  }

</script>

<style lang="scss">
  .contact-form__txt-aria-label,
  .contact-form__checkbox {
    align-items: center;
    padding: 10px 0;

    input:checked {
      background-color: var(--color-secondary);
      color: var(--color-primary);
    }
  }

  .contact-form__row {
    position: relative;
    margin-bottom: 15px;

    .icon-select{
      margin-right: 10px;
    }

    .error-icon {
      position: absolute;
      top: 10px;
      right: 2%;
      background: var(--color-secondary-accent);
      color: var(--color-primary);
    }
    .error {
      color: var(--color-secondary-accent);
      &::placeholder {
        color: inherit;
      }
    }
  }

  .contact-form__row textarea ~ .error-icon {
    top: auto; /* Remove any absolute top positioning for textarea */
    bottom: -25px; /* Place it below the textarea */
    right: 10px; /* Adjust horizontally if needed */
  }

  .contact-form__field-error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-secondary-accent);
    color: var(--color-primary);
    padding: 0 15px;
    overflow: hidden;
    max-height: 200px;
    transition: max-height 0.3s ease, opacity .3s ease, visibility .3s;

    &:after {
      content: "";
      top: 35px;
      left: 15px;
      border-width: 10px 20px 10px 20px;
      transform: rotate(180deg);
      border-style: solid;
      border-color: var(--color-secondary-accent) transparent transparent transparent;
    }

    .label-text {
      display: block;
      padding: 18px 0;
    }

     &.is-hidden {
      max-height: 0px;
      opacity: 0;
      visibility: hidden;
     }
  }

  .error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    background: var(--color-primary);
    color: var(--color-secondary-accent);
  }

  #ContactForm:target {
    scroll-margin-top: var(--header-height, 70px);
  }

  #ContactForm h2 {
    @media screen and (min-width: 750px) {
      max-width: 75%;
    }
  }
</style>


<section
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="wrapper grid grid--2" id="ContactForm">
    <h2>{contactFormData.title}</h2>
    {#if isValidated}
      <p>{contactFormData.submitMessage}</p>
    {:else}
      <form class="contact-form" on:submit={handleSubmit} novalidate>
        <div class="contact-form__row">
          <input
            type="text"
            placeholder="{contactFormData.nameText}{isRequired ? '*' : ''}"
            name="name"
            required={isRequired}
            bind:value={name}
            on:blur={validateName}
            on:input={validateName}
            class:error={!isValidName}
          >
          <span class="error-icon" class:is-hidden={isValidName}>!</span>
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="contact-form__field-error" class:is-hidden={isValidName || name.length === 0}>
            <span class="label-text">{contactFormData.nameError}</span>
          </label>
          </div>

        <div class="contact-form__row">
          <input
            type="email"
            placeholder="{contactFormData.emailText}{isRequired ? '*' : ''}"
            name="email"
            required={isRequired}
            bind:value={email}
            on:blur={validateEmail}
            on:input={validateEmail}
            class:error={!isValidEmail}
          >
            <span class="error-icon" class:is-hidden={isValidEmail}>!</span>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="contact-form__field-error" class:is-hidden={isValidEmail || email.length === 0}>
              <span class="label-text">{contactFormData.emailError}</span>
            </label>
        </div>


          <div class="contact-form__row">
          <input
            type="text"
            placeholder="{contactFormData.businessText}{isRequired ? '*' : ''}"
            name="business"
            required={isRequired}
            bind:value={business}
            class:error={!isValidBusiness}
          >
            <span class="error-icon" class:is-hidden={isValidBusiness}>!</span>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="contact-form__field-error" class:is-hidden={isValidBusiness || business.length === 0}>
              <span class="label-text">{contactFormData.businessError}</span>
            </label>
        </div>

        <div class="contact-form__row">
          <input
            type="tel"
            placeholder="{contactFormData.phoneText}{isRequired ? '*' : ''}"
            name="phone"
            minlength="7"
            maxlength="15"
            required={isRequired}
            bind:value={phone}
            on:blur={validatePhone}
            on:input={validatePhone}
            class:error={!isValidPhone}
          >
            <span class="error-icon" class:is-hidden={isValidPhone}>!</span>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="contact-form__field-error" class:is-hidden={isValidPhone || phone.length === 0}>
              <span class="label-text">{contactFormData.phoneErorr}</span>
            </label>
          </div>

        <div class="contact-form__row">
          <select name="select" bind:value={selectValue}>
            {#each contactFormData.selectOption as select}
            <option value={select.value}>{select.option}</option>
            {/each}
          </select>
          <span class="icon-select"></span>
        </div>

        <div class="contact-form__row">
          <label class="contact-form__txt-aria-label" for="textarea" name="text">{contactFormData.textArea}</label>
          <textarea rows="7" cols="20"
                    bind:value={textAreaValue}
                    on:blur={validateCharacterLimit}
                    on:input={validateCharacterLimit}
                    class:error={!isWithinCharacterLimit}
          ></textarea>
          <span class="error-icon" class:is-hidden={isWithinCharacterLimit}>!</span>


          <label class="contact-form__field-error" class:is-hidden={isWithinCharacterLimit || textAreaValue.length === 0}>
            <span class="label-text">{ contactFormData.characterLimitError }</span>
          </label>
        </div>

        <div class="contact-form__row">
          <label class="contact-form__checkbox">
            <input type="checkbox"
              name="accept"
              bind:checked={checked}
            >
            <span class="label-text">{contactFormData.checkBox}{isRequired ? '*' : ''}</span>
          </label>
        </div>

        <div class="contact-form__row">
          <button class="btn btn--solid-alt submit-btn" type="submit">{contactFormData.btnText}</button>
        </div>
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="contact-form__field-error" class:is-hidden={emptyFields === null || emptyFields}>
            <span class="label-text">{contactFormData.emptyFieldsError}</span>
            <span class="error-icon">!</span>
          </label>
      </form>
    {/if}
  </div>
</section>